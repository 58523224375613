<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <PilotCard ref="preview" v-bind:title="title" v-bind:example="true">
          <template v-slot:title>
            <h3 class="card-title">
              <slot name="title">Observations Assigned To Me</slot>
            </h3>
          </template>
          <template v-slot:toolbar>
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="custom-v-dropdown btn btn-clean btn-light-primary btn-sm btn-icon"
              no-caret
              right
              no-flip
            >
              <template v-slot:button-content>
                <i class="ki ki-bold-more-hor"></i>
              </template>
              <div class="navi navi-hover min-w-md-250px">
                <b-dropdown-text tag="div" class="navi-item">
                  <router-link to="/observations/create" v-slot="{ navigate }">
                    <div
                      class="d-flex align-items-center mb-5 cursor-pointer"
                      @click="navigate"
                    >
                      <div class="symbol symbol-40 symbol-light-info mr-5">
                        <span class="symbol-label">
                          <span class="svg-icon svg-icon-lg svg-icon-info">
                            <inline-svg
                              src="media/svg/icons/Code/Plus.svg"
                            ></inline-svg>
                          </span>
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column font-weight-bold text-left"
                      >
                        <a
                          class="text-dark text-hover-primary mb-0 font-size-lg"
                        >
                          New Observation
                        </a>
                        <span class="text-muted">
                          Log a new observation
                        </span>
                      </div>
                    </div>
                  </router-link>
                </b-dropdown-text>
                <b-dropdown-text tag="div" class="navi-item">
                  <export-excel
                    class="border-0"
                    :data="observations"
                    :fields="fields"
                    type="csv"
                    worksheet="observations"
                    name="observations.csv"
                  >
                    <div class="d-flex align-items-center mb-5 cursor-pointer">
                      <div class="symbol symbol-40 symbol-light-warning mr-5">
                        <span class="symbol-label">
                          <span class="svg-icon svg-icon-lg svg-icon-warning">
                            <inline-svg
                              src="media/svg/icons/Files/Export.svg"
                            ></inline-svg>
                          </span>
                        </span>
                      </div>
                      <div class="d-flex flex-column font-weight-bold">
                        <a
                          class="text-dark text-hover-primary mb-0 font-size-lg"
                        >
                          Export Observations
                        </a>
                        <span class="text-muted">
                          Export to excel
                        </span>
                      </div>
                    </div>
                  </export-excel>
                </b-dropdown-text>
                <b-dropdown-text tag="div" class="navi-item">
                  <div
                    @click="$bvModal.show('searchModal')"
                    class="d-flex align-items-center mb-5 cursor-pointer"
                  >
                    <div class="symbol symbol-40 symbol-light-primary mr-5">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-primary">
                          <inline-svg
                            src="media/svg/icons/General/Search.svg"
                          ></inline-svg>
                        </span>
                      </span>
                    </div>
                    <div class="d-flex flex-column font-weight-bold">
                      <a class="text-dark text-hover-primary mb-0 font-size-lg">
                        Advanced Search
                      </a>
                      <span class="text-muted">
                        Filter Observations
                      </span>
                    </div>
                  </div>
                </b-dropdown-text>
                <b-dropdown-text tag="div" class="navi-item">
                  <div
                    @click="refresh()"
                    class="d-flex align-items-center mb-5 cursor-pointer"
                  >
                    <div class="symbol symbol-40 symbol-light-success mr-5">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-success">
                          <inline-svg
                            src="media/svg/icons/Media/Repeat.svg"
                          ></inline-svg>
                        </span>
                      </span>
                    </div>
                    <div class="d-flex flex-column font-weight-bold">
                      <a class="text-dark text-hover-primary mb-0 font-size-lg">
                        Refresh
                      </a>
                      <span class="text-muted">
                        Refresh Observations
                      </span>
                    </div>
                  </div>
                </b-dropdown-text>
              </div>
            </b-dropdown>
          </template>
          <template v-slot:body>
            <template>
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <b-form-input
                    placeholder="Quick Search"
                    v-model="quickSearch"
                  ></b-form-input>
                </div>
              </div>
              <hr />
              <v-data-table
                :headers="headers"
                :items="observations"
                :loading="dataLoading"
                loading-text="Loading Observations..."
                :search="quickSearch"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50]
                }"
                :items-per-page="20"
                class="elevation-1"
              >
                <template v-slot:[`item.title`]="{ item }">
                  <span
                    v-b-tooltip.hover.top="item.title"
                    class="d-inline-block text-truncate cursor-pointer"
                    style="max-width: 100px;"
                  >
                    {{ item.title }}
                  </span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <span
                    class="label label-inline"
                    :class="getColor(item.status.name)"
                    >{{ item.status.name }}</span
                  >
                </template>

                <template v-slot:[`item.user`]="{ item }">
                  {{ item.user.firstname }} {{ item.user.lastname }}
                </template>

                <template v-slot:[`item.timestamp`]="{ item }">
                  <span
                    ><time-ago
                      class="text-sm"
                      :refresh="60"
                      :datetime="item.timestamp"
                      locale="en"
                      :long="true"
                      tooltip
                    ></time-ago
                  ></span>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <div class="w-100 text-right">
                    <span
                      @click="itemDetails(item.id)"
                      v-b-tooltip.hover.top="'Observation Details'"
                      class="btn btn-icon btn-light-primary btn-hover-light-primary btn-sm"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg
                          src="media/svg/icons/Layout/Layout-horizontal.svg"
                        ></inline-svg>
                      </span>
                    </span>
                    <span
                      @click="editItem(item.id)"
                      v-if="hasPermission('Administrator, Editor')"
                      v-b-tooltip.hover.top="'Edit Observation'"
                      class="btn btn-icon btn-light-warning btn-hover-light-warning btn-sm ml-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-warning">
                        <inline-svg
                          src="media/svg/icons/Communication/Write.svg"
                        ></inline-svg>
                      </span>
                    </span>
                    <span
                      @click="deleteItem(item.id)"
                      v-if="hasPermission('Administrator, Editor')"
                      v-b-tooltip.hover.top="'Delete Observation'"
                      class="btn btn-icon btn-light-danger btn-hover-light-danger btn-sm ml-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <inline-svg
                          src="media/svg/icons/General/Trash.svg"
                        ></inline-svg>
                      </span>
                    </span>
                  </div>
                </template>
              </v-data-table>
              <b-modal id="searchModal" hide-footer>
                <template #modal-title>
                  Advanced Search
                </template>
                <div class="d-block text-center">
                  <div class="mb-2">
                    <b-form-input
                      size="lg"
                      v-model="keyword"
                      placeholder="Keyword"
                    ></b-form-input>
                  </div>
                  <div class="mb-2">
                    <multiselect
                      v-model="status"
                      :options="statuses"
                      track-by="name"
                      label="name"
                      placeholder="Filter By Status"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                  </div>
                  <div class="mb-2">
                    <multiselect
                      v-model="category"
                      :options="categories"
                      track-by="name"
                      label="name"
                      placeholder="Filter By Category"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                  </div>
                  <div class="mb-2">
                    <multiselect
                      v-model="type"
                      :options="types"
                      track-by="name"
                      label="name"
                      placeholder="Filter By Types"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                  </div>
                  <div class="mb-2">
                    <multiselect
                      v-model="location"
                      :options="locations"
                      track-by="name"
                      label="name"
                      placeholder="Filter By Location"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                  </div>
                  <div class="mb-2">
                    <b-form-datepicker
                      :max="max"
                      v-model="startDate"
                      placeholder="Start Date"
                      class="text-left"
                    ></b-form-datepicker>
                  </div>
                  <div class="mb-2">
                    <b-form-datepicker
                      :max="max"
                      v-model="endDate"
                      placeholder="End Date"
                      class="text-left"
                    ></b-form-datepicker>
                  </div>
                </div>
                <b-button
                  class="mt-3"
                  block
                  variant="primary"
                  @click="advancedSearch()"
                  >SEARCH</b-button
                >
              </b-modal>
            </template>
          </template>
        </PilotCard>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import ObservationService from "@/core/services/observation.service.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PilotCard from "@/view/content/Card.vue";
import Multiselect from "vue-multiselect";
import GeneralService from "@/core/services/general.service.js";
import ActionPartyService from "@/core/services/actionparty.service.js";
import LocationService from "@/core/services/location.service.js";
import ObservationClassificationService from "@/core/services/observation-classification.service.js";
import ObservationTypesService from "@/core/services/observation-types.service.js";
import { TimeAgo } from "vue2-timeago";
//import Swal from "sweetalert2";
export default {
  props: {
    title: String
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    return {
      fields: {
        "Observation Title": "title",
        "Location ": "location.name",
        "Classification ": "classification.name",
        "Type ": "observation_type.name",
        "Timestamp ": "timestamp",
        "Status ": "status.name",
        "Assigned To": {
          field: "action_party_users",
          callback: value => {
            return `${value.firstname} ${value.lastname}`;
          }
        },
        "Action Party Group": "action_party_groups.name",
        "Description ": "description",
        "Action Taken ": "action_taken",
        "Recommendation ": "recommendation"
      },
      headers: [
        {
          text: "Observation",
          align: "start",
          sortable: false,
          value: "title"
        },
        { text: "Location", value: "location.name" },
        { text: "Category", value: "classification.name" },
        { text: "Type", value: "observation_type.name" },
        { text: "Timestamp", value: "timestamp" },
        { text: "Status", value: "status" },
        { text: "Created By", value: "user" },
        { text: "", value: "action" }
      ],
      max: maxDate,
      observations: [],
      rowsPerPageItems: [20, 40, 80, 100],
      quickSearch: "",
      keyword: null,
      locations: [],
      statuses: [],
      categories: [],
      types: [],
      location: null,
      status: null,
      category: null,
      type: null,
      startDate: null,
      endDate: null,
      assigned: true,
      dataLoading: true
    };
  },
  components: {
    PilotCard,
    TimeAgo,
    Multiselect
  },
  methods: {
    getActionParties() {
      ActionPartyService.all()
        .then(response => {
          this.parties = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate action parties at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getLocations() {
      LocationService.all()
        .then(response => {
          this.locations = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate locations at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getCategories() {
      ObservationClassificationService.all()
        .then(response => {
          this.categories = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate classifications at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getTypes() {
      ObservationTypesService.all()
        .then(response => {
          this.types = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate observation types at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getStatuses() {
      GeneralService.statuses()
        .then(response => {
          this.statuses = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate statuses at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getColor(status) {
      console.log(status);
      if (status == "Open") return "label-light-danger";
      else if (status == "Pending") return "label-light-warning";
      else return "label-light-success";
    },
    exportExcel() {
      console.log(status);
      if (status == "open") return "bg-danger";
      else if (status == "pending") return "bg-warning";
      else return "bg-success";
    },
    advancedSearch() {
      // eslint-disable-next-line prettier/prettier
      let slug = `?keyword=${this.keyword}&location=${this.location ? this.location.id : ""}&classification=${this.category ? this.category.id : ""}&observation_type=${this.type ? this.type.id : ""}&status=${this.status ? this.status.id : ""}&date_from=${this.startDate}&date_to=${this.endDate}&assigned=${this.assigned}`;
      this.getObservations(slug);
      this.$bvModal.hide("searchModal");
    },
    getObservations(slug) {
      ObservationService.all(slug)
        .then(response => {
          this.observations = response.data;
          this.dataLoading = false;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate observations at the moment, please give it a minute and refresh page"
          );
          this.dataLoading = false;
          console.log(error);
        });
    },
    editItem(id) {
      this.$router.push(`/observations/edit/${id}`);
    },
    itemDetails(id) {
      this.$router.push(`/observations/details/${id}`);
    },
    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this observation",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return ObservationService.delete(id)
            .then(response => {
              // eslint-disable-next-line prettier/prettier
              let slug = `?keyword=${this.keyword}&location=${this.location ? this.location.id : ""}&classification=${this.category ? this.category.id : ""}&observation_type=${this.type ? this.type.id : ""}&status=${this.status ? this.status.id : ""}&date_from=${this.startDate}&date_to=${this.endDate}&assigned=${this.assigned}`;
              this.getObservations(slug);
              return response.data;
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Operation Failed: ${error.data.message}`
              );
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          this.makeToast(
            "success",
            "Observation Deleted",
            "Observation was successfully deleted"
          );
        }
      });
    },
    refresh() {
      this.observations = [];
      this.quickSearch = "";
      this.keyword = null;
      this.location = null;
      this.status = null;
      this.category = null;
      this.type = null;
      this.startDate = null;
      this.endDate = null;
      this.dataLoading = true;
      let slug = `?assigned=${this.assigned}`;
      this.getObservations(slug);
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    },
    ...mapGetters(["currentUser", "layoutConfig", "hasPermission"])
  },
  mounted() {
    let slug = `?assigned=${this.assigned}`;
    this.getObservations(slug);
    this.getLocations();
    this.getCategories();
    this.getTypes();
    this.getStatuses();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Observations", route: "/observations" },
      { title: "Assigned Observation" }
    ]);
  }
};
</script>
